.markerCluster {
  border-radius: 50%;
  font-size: 0.6vw;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  text-transform: uppercase;
  background-color: #f7f305;
  border: 3px solid #fff;
  padding: 5px 10px;
  box-shadow: 3px 5px 8px #888888;
}

.markerClusterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}
