.content {
  background: #fff;
  margin: 0 30px;
}

.main {
  margin: 30px;
  float: right;
  text-align: right;
}

.rowDragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.rowDragging td {
  padding: 16px;
}

.rowDragging .dragVisible {
  visibility: visible;
}
