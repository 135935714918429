body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Global styles */
/* Header */
.ant-layout-header {
  padding: 0;
  background-color: rgba(0, 153, 255, 0.5);
  height: 50px;
}

.ant-menu-sub {
  background-color: #fff !important;
}

.ant-menu-submenu .ant-menu-item a {
  color: #5a5a5a !important;
}
/* Forms */
.ant-form-vertical .ant-form-item {
  padding-bottom: 0 !important;
}
