.ticketResultsBar {
  background-color: #fff;
  margin-top: 10px;
  padding: 5px 5px 5px 15px;
  position: absolute;
  z-index: 1000;
  margin-left: 79px;
  margin-right: 70px;
  border: 2px solid #a5bea5;
}
