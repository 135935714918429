.tripEditHeader {
  padding: 20px 20px 0 20px;
}

.actionBar {
  /* background: rgba(255, 255, 255, 0.5); */
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;
}

.saveButton {
  float: right;
  margin-right: 10px;
}
