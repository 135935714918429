.ticketListItem {
  padding: 10px;
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.ticketListItem .avatar {
  vertical-align: 'middle';
  background-color: #76bff7;
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin: 7px 10px 0 0;
}

.ticketListItem .avatar:hover,
.ticketListItem .location:hover {
  cursor: grab;
}

.ticketListItem .location {
  vertical-align: 'middle';
  background-color: #c30b82;
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin: 7px 10px 0 0;
}

.ticketListItem .right {
  float: right;
  text-align: right;
  margin-right: 5px;
}
