.header {
}
.logo {
  color: #eee;
  font-weight: 600;
  letter-spacing: 4px;
  line-height: 50px;
  width: 200px;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.2); */
  padding: 0 28px 0 28px;
  float: left;
  background-color: rgba(0, 153, 255, 0.5);
  text-align: left;
}

.version {
  letter-spacing: 1px;
  color: #fff;
  font-size: 9px;
  padding-left: 5px;
  display: inline;
}
