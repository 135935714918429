.header {
  margin-bottom: -27px;
}

.map {
  position: absolute;
  top: 125px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
