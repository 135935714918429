.markerPin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  /* box-shadow: -3px 8px 10px #888888; */
}

.markerPin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  color: '#fff';
}

.icon {
  position: absolute;
  width: 22px;
  left: 0;
  right: 0;
  margin: 14px auto;
  font-size: 13px;
  text-align: center;
  color: '#fff';
}

.text {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  font-size: 13px;
  text-align: center;
  color: '#fff';
  border-radius: 50%;
  /* font-weight: bold; */
}
