.markerPin {
  position: absolute;
  left: 50%;
  top: 50%;

  background: #fff;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #fff;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  -webkit-box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
}

.markerPin::after {
}

.icon {
  position: absolute;
  width: 22px;
  left: 0;
  right: 0;
  margin-top: 5px;
  margin-left: 0px;
  font-size: 13px;
  text-align: center;
  color: '#fff';
}

.text {
  display: block;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 0px;
  font-weight: bold;
  color: #333;
  text-align: center;
  transform: rotate(45deg);
}
