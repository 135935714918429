.tripEdit .sidebar {
  background: '#fff' !important;
  border-right: '1px solid #eee' !important;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.tripEdit .sidebar .content {
  z-index: 1;
  background: '#fff';
  padding: 0;
  margin: 0;
  min-height: 280;
}

.tripEdit .main {
  padding: 0;
}

.tripEdit .main .header {
  z-index: 1;
  background: '#fff';
  padding: 24;
  margin: 0;
  min-height: 100;
  position: absolute;
  top: 50px;
  left: 375px;
  right: 0;
}

.tripEdit .main .map {
  z-index: 1;
  position: absolute;
  top: 320px;
  bottom: 0;
  left: 375px;
  right: 0;
  padding: 0;
  margin: 0;
}
