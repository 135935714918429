.loader {
  /* position: absolute; */
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  width: 100%;
  height: 100%;
}

.spinner {
  background: #fff;
  /* border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; */
  width: 100%;
  padding: 20px;
  text-align: center;
}
